import React, { useEffect, useState } from "react";
import { ApiName } from "../utils/ApiName";
import tagManagerEvents from "../utils/GoogleTagManager";
import Awareness from "../components/Awareness";
import {
  awarenessFlowService,
  checkUtmService,
} from "../Services/Subscription";
import { IAwarenessFlowResp, ICheckUtmResp } from "../@types/ApiResponse";

type Props = {
  token: string | null;
};

let adNetwork = "";
const url = new URL(window.location.href);
const searchParams = new URLSearchParams(url.search);
const utmSource: any = searchParams.get("utm_source");
let qsPackageId: string | null = searchParams.get("package_id")
  ? searchParams.get("package_id")
  : "0";
const AwarenessScreen = (props: Props) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isHidden2, setIsHidden2] = useState(true);

  useEffect(() => {
    let pasredPackageId = parseInt(qsPackageId as string);
    if (
      Number.isNaN(pasredPackageId) ||
      pasredPackageId > 5 ||
      pasredPackageId < 0
    ) {
      qsPackageId = "0";
    }
    if (props.token) {
      checkUtm(utmSource);
      getFlow(utmSource);
    }
  }, [props.token]);

  const checkUtm = async (utmSource: string) => {
    try {
      let response: ICheckUtmResp = await checkUtmService(props.token);
      if (response.code === 0) {
        {
          response.result.map((e: any) => {
            if (utmSource === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
          console.log("ad_network", adNetwork);
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const getFlow = async (utmSource: string) => {
    try {
      let data = {
        utm_source: utmSource,
        package_id: qsPackageId as string,
      };
      let response: IAwarenessFlowResp = await awarenessFlowService(
        data,
        props.token
      );
      if (response.code === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "tiktok" + qsPackageId);
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        setIsHidden(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOverLay = () => {
    setIsHidden(true);
  };

  return <Awareness handleOverLay={handleOverLay} isHidden={isHidden} />;
};

export default AwarenessScreen;
